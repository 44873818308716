<template>
  <news-container :symbol="symbol" />
</template>

<script>
import NewsContainer from "@/components/NewsContainer";
export default {
  name: "NewsArticleView",
  components: {NewsContainer},
  props: ['symbol']
}
</script>

<style scoped>

</style>
