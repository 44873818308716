<template>
  <div>
    <div v-if="error" class="error">{{error}}</div>
    <ul class="sticky-list">
      <li v-for="btnTf in timeframes" :key="btnTf">
        <button :class="btnTf == timeframe ? 'active' : ''" @click="timeframe = btnTf">{{btnTf}}</button>
      </li>
    </ul>
    <object-renderer :value="stats" :is-root="true"/>
  </div>
</template>

<script>
import ObjectRenderer from "@/components/util/ObjectRenderer";
export default {
  name: "CompanyStats",
  components: {ObjectRenderer},
  props: ['symbol'],
  data() {
    return {
      stats: null,
      defaultTimeframe: 20,
      timeframe: null,
      timeframes: [],
      error: null
    }
  },
  methods: {
    async getStats() {
      this.error = null
      let timeframe = ''
      if (this.timeframe !== null) {
        timeframe = '&timeframe=' + this.timeframe
      }
      const response = await fetch('https://external.trading.10n.io/company/stats?symbol=' + this.symbol + timeframe)
      if (response.status !== 200) {
        this.error = response.statusText
        const errorText = await response.json()
        if (errorText) {
          this.error = errorText
        }
        return
      }
      const result = await response.json()
      if (this.timeframe === null) {
        this.timeframes = result
        this.timeframe = this.defaultTimeframe
        return
      }
      this.stats = result
    }
  },
  created() {
    this.getStats()
  },
  watch: {
    timeframe: function() {
      this.getStats()
    }
  }
}
</script>

<style scoped>
.sticky-list {
  list-style: none;
  padding: 0;
  position: sticky;
  top: 178px;
  width: 100%;
  background-color: #0a2036;
}
.sticky-list li {
  display: inline;
}
</style>
