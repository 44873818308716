<template>
  <s-e-c-filings  :symbol="$route.params.symbol" :key="$route.params.symbol" />
</template>

<script>
import SECFilings from "@/components/SECFilings";
export default {
  name: "SecFilingsView",
  components: {SECFilings}
}
</script>

<style scoped>

</style>
