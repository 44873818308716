<template>
  <div>
    <market-overview />
  </div>
</template>

<script>
import MarketOverview from "@/components/MarketOverview";
export default {
  name: "HomeView",
  components: {MarketOverview}
}
</script>

<style scoped>

</style>
