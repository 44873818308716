<template>

    <article>
      <news-container/>
    </article>

</template>

<script>
import NewsContainer from "@/components/NewsContainer";

export default {
  name: "MarketOverview",
  components: {NewsContainer}
}
</script>

<style scoped>

aside {
  background-color: #0a2036;
  position: fixed;
  width: 100%;
}

article {
  width: 100%;
  height: 100%;
}
@media only screen and (min-width: 768px) {

  aside {
    width: 33%;
    height: 100%;
  }

  article {
    width: 66%;
    height: 100%;

    margin-left: 33%;
    padding-left: 50px;
  }
}
</style>
