<template>
  <header class="background-color">
    <h1>{{ $arrayToString(anchor.labels) }} - {{ $nodeToString(anchor) }}</h1>
    <ul>
      <li>
        <toggle off-label="Exclude" on-label="Include" v-model="include" @change="$parent.getNodes()"/>
      </li>
      <li v-for="(excluded, type) in relationshipTypes" :key="type">
        <button :class="excluded?'active':'selected'" @click="$parent.toggleExclusions(type)">{{ type }}</button>
      </li>
    </ul>
  </header>
</template>

<script>
import Toggle from '@vueform/toggle'
export default {
  name: "LinkHeader",
  props: ['relationshipTypes', 'anchor'],
  components: {Toggle},
  data() {
    return {
      include: false,
    }
  },
  methods: {
    arrayToString(ar) {
      const arType = typeof ar
      if (arType === "object" || arType === "array") {
        ar = Object.values(ar).map(a => a.replace('NER_', '')).join(", ")
      }
      return ar
    },
    nodeToString(node) {
      if (!node || !node.properties) {
        return ''
      }
      const properties = node.properties
      if (properties.title) {
        return properties.title
      }
      if (properties.name) {
        return properties.name
      }
      if (properties.symbol) {
        return properties.symbol
      }
      // return null
      return this.$objectToString(node.properties, ['uid', 'id'])
    },
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0 0 0 2px;
}

li {
  display: inline;
}

h1 {
  font-size: 1.2em;
  margin: 0;
}

.back {
  float: right;
  margin-right: 10px;
  font-size: 1.1em;
  font-weight: bold;
}
</style>
