<template>
  <ul>
    <li :class="linkSelected?'selected-item':''" @click.stop="toggle">
      <font-awesome-icon icon="fa-solid fa-arrows-left-right-to-line"/>
    </li>
    <li v-if="isA(relationship.node, 'URL')"><a :href="relationship.node.properties.url"
                                                             target="_blank">
      <font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square"/>
    </a></li>
    <li v-if="isA(relationship.node, 'HOST')"><a :href="'http://' + relationship.node.properties.name"
                                                              target="_blank">
      <font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square"/>
    </a></li>
  </ul>
</template>

<script>

import {library} from "@fortawesome/fontawesome-svg-core";
import {faArrowsLeftRightToLine, faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";

library.add(faArrowsLeftRightToLine)
library.add(faArrowUpRightFromSquare)

export default {
  name: "LinkActions",
  props: ['relationship'],
  data() {
    return {
      linkSelected: false
    }
  },
  methods: {
    toggle() {
      this.linkSelected = !this.linkSelected

      this.$emit('link-selected', this.relationship.node, this.linkSelected)
    },
    isA(node, type) {
      return Object.values(node.labels).indexOf(type) !== -1
    },
  }
}
</script>

<style scoped>

ul {
  font-size: 1.3em;
  text-align: right;
  padding: 0;
  margin: 0;
}

li {
  margin-right: 10px;
  margin-left: 10px;
  display: inline;
}

a {
  text-decoration: none;
}
</style>
