<template>
  <div>
    <nav>
      <index-summary />
      <search-companies/>
      <current-symbol />
      <security-navigation v-if="$route.params.symbol" />
    </nav>
    <div class="container" :class="$route.params.symbol ? 'lowered' : ''">
      <router-view/>
    </div>
  </div>
</template>

<script>
import SearchCompanies from "@/components/SearchCompanies";
import IndexSummary from "@/components/IndexSummary";
import SecurityNavigation from "@/components/SecurityNavigation";
import CurrentSymbol from "@/components/CurrentSymbol";

export default {
  components: {CurrentSymbol, SecurityNavigation, IndexSummary, SearchCompanies}
}
</script>
<style>

/* https://paletton.com/#uid=33-0u0kkbm61EEmaIs-tXfHTk9t */
body, .has-background-color {
  background-color: #0a2036;
}

#app {
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #EAF0F5;
  padding-left: 4px;
  padding-right: 4px;
}

.container {
  margin-top: 110px;
}

.lowered {
  margin-top: 180px;
}

nav {
  position: fixed;
  background-color: #0a2036;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-active, .selected-item {
  color: #42b983;
}


a:visited, a:active, a:link {
  color: #EAF0F5;
}

button {
  background-color: #0B2F51;
  border: 1px solid #305272;
  color: #667D94;
  padding: 6px 9px 6px 9px;
  margin: 3px;
  border-radius: 3px;
}

button.active {
  background-color: #B7BBC0;
  color: #0B2F51;
}

button.selected {
  background-color: #0B2F51;
  color: #B7BBC0;
}

.error {
  background: #c51244;
  padding: 10px;
  border-radius: 0;
  position: relative;
  display: inline-block;
  box-shadow: 1px 1px 1px #aaaaaa;
  margin-top: 10px;
}

.unicode {
  font-family: Arial;
}
</style>
