<template>
  <article>
    <news-container :search="$route.params.search"/>
  </article>
</template>

<script>
import NewsContainer from "@/components/NewsContainer";
export default {
  name: "SearchView",
  components: {NewsContainer}
}
</script>

<style scoped>

</style>
