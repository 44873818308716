<template>
  <div>
    <h1 v-if="!symbol">
      Latest News
    </h1>
    <ul>
      <li v-for="newsItem in news" :key="newsItem.id">
        <h3 class="fade"><span class="type">{{ flatten(newsItem.type).replace('_', ' ') }}</span><a :href="newsItem.url"
                                                                                                    target="_blank">{{
            newsItem.title
          }}</a>
        </h3>
        <p>{{
            new Date(newsItem.date_published).toLocaleDateString(
                'en-us',
                {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  timeZone: 'utc'
                }
            )
          }}
          <span v-if="!symbol && newsItem.symbols">
          - Symbols: {{ this.flatten(newsItem.symbols) }}
          </span>
          <span> - {{ filterDomainName(newsItem.url) }}</span>
        </p>
        <p class="fade">... {{ newsItem.text_preview }} ... </p>
        <ul class="labels">
          <li
              v-for="(words, label) in newsItem.linked"
              :key="label"
              class="label"
              @click="labelSelected[newsItem.id + label] = !labelSelected[newsItem.id + label]"
              :class="labelSelected[newsItem.id + label] ? 'label-selected' : ''"
          >
            <span>{{ label }}</span>
            <span v-for="word in words" :key="word.id" @click="node = word.node">{{ word.value }}</span>
          </li>
        </ul>
      </li>
    </ul>
    <div class="catcher" v-if="node" @click="node = null">
      <div class="node-container has-background-color">
        <node-display :node="node"/>
      </div>
    </div>
  </div>
</template>

<script>
import NodeDisplay from "@/components/link/NodeDisplay";

export default {
  name: "NewsContainer",
  components: {NodeDisplay},
  props: ['symbol', 'search'],
  data() {
    return {
      labelSelected: {},
      news: [],
      node: null,
      timer: false
    }
  },
  methods: {
    async loadNews(res) {
      this.news = await res.json()
    },
    flatten(v) {
      if (typeof v === "object") {
        v = v.join(', ')
      }
      return v
    },
    filterDomainName(url) {
      url = new URL(url)
      return url.hostname
    },
    getNews() {
      let url = 'https://external.trading.10n.io/search/search'
      if (this.symbol) {
        url += '?symbol=' + this.symbol
      } else if (this.search) {
        url += '?search=' + this.search
      }
      fetch(url).then(this.loadNews)
    }
  },
  mounted() {
    this.getNews()
    this.timer = setInterval(this.getNews, 15000)
  },
  unmounted() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 3px;
}

h3 {
  font-weight: normal;
  margin-bottom: 3px;
  max-height: 73px;
  overflow: hidden;
}

p {
  margin-top: 3px;
  font-size: 0.9em;
  max-height: 200px;
  overflow: hidden;
}

.type {
  background-color: #7C91A4;
  padding: 2px 6px 2px 6px;
  margin-right: 6px;
  font-size: 0.9em;
  border-radius: 3px;
}

.fade {
  position: relative;
  /* … */
}

.fade:after {
  content: "";
  position: absolute;
  top: 170px;
  left: 0;
  height: 30px;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), #0a2036);
}

.label {
  display: inline-block;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8em;
  white-space: nowrap;
  cursor: pointer;
}

.label-selected {
  max-width: none;
}

.label span:first-child {
  font-size: 1.1em;
  background-color: #293e54;
  color: #EAF0F5;
  padding: 0 5px 0 5px;
}

.label span {
  margin-right: 10px;
}
.node-container {
  z-index: 10000;
  position: absolute;
  overflow: scroll;
  top: 220px;
  bottom: 100px;
  left: 0;
  border-radius: 5px;
  border: 1px solid #637991;
  max-width: 60%;
}

.catcher {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
