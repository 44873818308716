<template>
  <ul>
    <li v-for="node in nodes" :key="node.id">
      <p class="labels" @click="go(node)">
        <span >
          <font-awesome-icon icon="fa-solid fa-angles-right" />
        </span>
        {{$labelToWords($arrayToString(node.labels))}}
      </p>
      <p class="properties" v-html="$objectToString(node.properties, ['uid'])"></p>
    </li>
  </ul>
</template>

<script>
import {library} from "@fortawesome/fontawesome-svg-core";
import {faAnglesRight} from "@fortawesome/free-solid-svg-icons";

library.add(faAnglesRight)

export default {
  name: "NodeRelationships",
  props: ['node', 'relationship'],
  data() {
    return {
      nodes: []
    }
  },
  methods: {
    go(node) {
      const newRoute = {
        name: 'relationships',
        params: {
          symbol: this.$route.params.symbol,
          relationships: node.id
        }
      }
      this.$router.push(newRoute)
    },
    async getRelationships() {
      const response = await fetch('https://external.trading.10n.io/company/relationships_by_type?id=' + this.node.id+'&relationship=' + this.relationship)
      this.nodes = await response.json()

    }
  },
  mounted() {
    this.getRelationships()
  }
}
</script>

<style scoped>
ul {
  text-align: left;
  list-style: none;
  padding: 0;
  margin: 0;
}
li {
  border-bottom: 1px solid #063A6D;
  padding-bottom: 3px;
  padding-top: 3px;
}
p {
  margin: 0;
}

.labels {
  font-size: 0.95em;
}

.properties {
  font-size: 0.85em;
}

.labels span {
  float: right;
}

</style>
