<template>
  <div>
    <dl>
      <dt>Name</dt>
      <dd>{{name}}</dd>
      <dt>CIK</dt>
      <dd>{{cik}}</dd>
      <dt>IR Endpoint</dt>
      <dd>{{ir_endpoint}}</dd>
      <dt>Industry</dt>
      <dd>{{industry}}</dd>
      <dt v-if="primaryExchange">Exchange</dt>
      <dd v-if="primaryExchange">{{primaryExchange}}</dd>
      <template v-for="(address, type) in addresses" :key="type">
        <dt>{{ucFirst(type)}} Address</dt>
        <dd class="address-container">
          <dl>
            <template v-for="(value, part) in address" :key="part + type">
              <dt>{{part}}</dt>
              <dd>{{value}}</dd>
            </template>
          </dl>
        </dd>
      </template>
    </dl>
  </div>
</template>

<script>
export default {
  name: "AboutCompany",
  props: ['symbol'],
  data() {
    return {
      name: null,
      cik: null,
      addresses: null,
      ir_endpoint: null,
      industry: null,
      primaryExchange: null
    }
  },
  methods: {
    ucFirst(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    async getData() {
      const response = await fetch('https://external.trading.10n.io/company/about?symbol=' + this.symbol)
      const data = await response.json()
      this.name = data.name
      this.cik = data.cik
      this.addresses = data.addresses
      this.ir_endpoint = data.ir_endpoint
      this.industry = data.industry
      this.primaryExchange = data.primary_exchange
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>
dt {
  position: absolute;
}
dd {
  margin-left: 100px;
}
.address-container {
  padding-top: 10px;
}
.address-container dt {
  margin-left: -70px;
}
.address-container dd {
  margin-left: 80px;
}
</style>
