<template>
  <div>
    <ul>
      <li>
        <button :class="screen === 'comparison' ? 'active': ''" @click="screen = 'comparison'">Comparison</button>
      </li>
      <li>
        <button :class="screen === 'list' ? 'active': ''"  @click="screen = 'list'">List</button>
      </li>
    </ul>
    <industry-comparison :symbol="$route.params.symbol" :key="'comparison' + $route.params.symbol" v-if="screen === 'comparison'"/>
    <industry-list :symbol="$route.params.symbol" :key="'list' + $route.params.symbol" v-if="screen === 'list'"/>
  </div>
</template>

<script>
import IndustryComparison from "@/components/IndustryComparison";
import IndustryList from "@/components/IndustryList";
export default {
  name: "IndustryComparisonView",
  components: {IndustryList, IndustryComparison},
  data() {
    return {
      screen: 'comparison'
    }
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  display: inline;
  margin-right: 20px;
}
</style>
