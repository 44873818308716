<template>
  <div class="background" :style="myStyle">
    <link-header :relationship-types="relationshipTypes" :anchor="anchor"/>
    <div>
      <template v-for="(r, key) in relationships" :key="key">
        <div v-if="r.node && r.node.labels" class="bubble" @click.stop="toggleNodeRelationships(key)">
          <h2>{{ $relationshipToWords(r.type) }} {{ $arrayToString(r.node.labels) }}: </h2>
          <div v-html="$nodeToString(r.node)"></div>
          <link-actions :relationship="r" @link-selected="linkSelected"/>
        </div>
        <link-view :node_id="key" v-if="selectedComponents[key]" :z-index="(this.myStyle.zIndex * 1) - 1000 + 1"
                   @link-selected="linkSelected"
                   @toggle-node-relationship="toggleNodeRelationships"
        />
      </template>
    </div>
  </div>
</template>

<script>

import LinkActions from "@/components/link/LinkActions";
import LinkHeader from "@/components/link/LinkHeader";

export default {
  name: "LinkView",
  components: {LinkHeader, LinkActions},
  props: ['symbol', 'node_id', 'zIndex', 'exclusions'],
  data() {
    return {
      relationships: {},
      relationshipTypes: {},
      anchor: {},
      selectedComponents: {},
      fontSize: 10,
      myStyle: {
        paddingTop: '180px'
      }
    }
  },
  methods: {
    linkSelected(node, selected) {
      this.$emit('link-selected', node, selected)
    },
    toggleExclusions(type) {
      this.relationshipTypes[type] = !this.relationshipTypes[type]
      this.getNodes()
    },
    closePane() {
      this.myStyle.opacity = 0
      this.myStyle.paddingTop = '180px'
      setTimeout(() => this.$parent.toggleNodeRelationships(this.node_id), 250)
    },
    updateHeaderPosition() {
      for (let i in this.selectedComponents) {
        if (this.selectedComponents[i]) {
          setTimeout(() => {
            this.fontSize = 12
            this.myStyle.fontSize = this.fontSize + 'px'
            this.myStyle.paddingTop = '250px'
          }, 250)
          return
        }
      }
      this.fontSize = 16
      this.myStyle.fontSize = this.fontSize + 'px'
      this.myStyle.paddingTop = '180px'
    },
    toggleNodeRelationships(nodeId) {
      this.selectedComponents[nodeId] = true
      this.updateHeaderPosition()
      let nodes = []
      if (this.$route.params && this.$route.params.relationships) {
        nodes = this.$route.params.relationships.split(',')
      }
      if (nodes.indexOf(nodeId) === -1) {
        nodes.push(nodeId)
        this.$router.push({
          name: 'links',
          params: {
            symbol: this.$route.params.symbol,
            relationships: nodes.join(',')
          }
        })
      }
    },
    async getNodes() {
      let url = 'https://external.trading.10n.io/company/relationships?'
      if (this.node_id) {
        url += 'id=' + this.node_id
      } else {
        url += 'symbol=' + this.symbol
      }
      let exclusions = []
      for (let r in this.relationshipTypes) {
        if (this.relationshipTypes[r]) {
          exclusions.push(r)
        }
      }
      if (exclusions.length > 0) {
        url += '&restrictions=' + exclusions.join(',')
        if (this.include) {
          url += '&operation=inclusion'
        } else {
          url += '&operation=exclusion'
        }
      }
      // url += '&XDEBUG_SESSION_START=1'
      const request = await fetch(url)
      let data = await request.json()
      this.relationships = {}
      this.anchor = data.anchor
      for (let i in data.relationships) {
        const rel = data.relationships[i]
        for (let r in rel) {
          if (data.nodes[rel[r].start] && data.nodes[rel[r].end]) {
            const label = this.$arrayToString(data.nodes[rel[r].start].labels)
            if (!this.relationships[label]) {
              this.relationships[label] = []
            }
            let node = data.nodes[rel[r].start]
            if (node.id === this.anchor.id) {
              node = data.nodes[rel[r].end]
            }
            if (!this.relationships[node.id]) {
              delete node.properties.uid
              if (!this.relationshipTypes[rel[r].type]) {
                this.relationshipTypes[rel[r].type] = false
              }
              this.relationships[node.id] = {node: node, type: rel[r].type}
            }
          }
        }
        this.handleRelationshipParameters()
      }
      setTimeout(() => {
            this.myStyle.fontSize = '16px'
            this.myStyle.opacity = 1
          }
          , 500
      )
    },
    handleRelationshipParameters() {
      if (!this.$route.params || !this.$route.params.relationships) {
        for (let i in this.selectedComponents) {
          if (this.selectedComponents[i]) {
            this.selectedComponents[i] = false
          }
        }
        this.updateHeaderPosition()
        return
        // } else if (this.symbol) {
        //   console.log('I am the root node')
        //   return
      }
      const params = this.$route.params.relationships.split(',')
      const selfKey = params.indexOf(this.node_id)
      const nextKey = selfKey + 1
      // console.log('Params', params, selfKey, nextKey)
      // Either not found or not the last one in the chain
      if (nextKey === params.length - 1) {
        const nextNode = params[nextKey]
        if (this.relationships[nextNode] && !this.selectedComponents[nextNode]) {
          this.toggleNodeRelationships(nextNode)
        }
      //   return
      // } else if (selfKey === params.length - 1) {
      //   // console.log('I am at the end of the chain, so stop here')
      //   return
      }
    }
  },
  mounted() {
    if (!this.zIndex) {
      this.myStyle.zIndex = 1000
    } else {
      this.myStyle.zIndex = 1000 + this.zIndex
    }
    this.myStyle.fontSize = this.fontSize + 'px'
    this.myStyle.opacity = 0
    this.myStyle.paddingTop = '180px'

    this.getNodes()
  },
  watch: {
    $route: function () {
      // console.log(as, df)
      this.handleRelationshipParameters()
    }
  }
}
</script>

<style scoped>


.bubble {
  font-size: 0.9em;
  position: relative;
  display: inline-block;
  border: 1px solid #B7BBC0;
  border-radius: 15px;
  padding: 5px;
  margin: 5px;
  max-width: 40%;
  background-color: #2c3e50;
  text-align: center;
  word-wrap: break-word;
  text-overflow: clip;
  vertical-align: middle;

}

.background {
  background-color: rgba(10, 32, 54, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  transition-duration: 300ms;
  max-width: 100%;
  width: 100%;
  min-height: 100%;
}

h2 {
  font-size: 1em;
  margin: 0;
}

button {
  font-size: 1.05em;
}


:deep(.toggle) {
  width: 100px;
  text-align: center;
  justify-content: center;
}

</style>
