<template>
  <div class="info-container">
    <url-info v-if="$arrayToString(node.labels) === 'URL'" :node="node" />
    <dl v-else>
      <dt>Type</dt>
      <dd>{{$arrayToString(node.labels)}}</dd>
      <template v-for="(value, name) in data" :key="name">
        <dt>{{name}}</dt>
        <dd>{{value}}</dd>
      </template>
    </dl>
  <table>
    <template  v-for="(value, relationship) in relationships" :key="relationship">
      <tr @click="toggleRelationship(relationship)">
        <td>{{$relationshipToWords(relationship)}}</td>
        <td>{{value}}</td>
      </tr>
      <tr v-if="selectedRelationship === relationship">
        <td colspan="2">
          <node-relationships :node="node" :relationship="relationship" />
        </td>
      </tr>
    </template>
  </table>
    <button v-if="attachClicked || ignoreClicked || detachClicked || detachOthersClicked" class="active" @click="commitClicked" style="float: right">
      <font-awesome-icon icon="fa-solid fa-square-check" />
    </button>
    <button @click="actionSelect('ignore')" :class="ignoreClass"><font-awesome-icon icon="fa-solid fa-ban" /></button>
    <button
        v-if="baseNodes.indexOf(this.node.id) === -1"
        @click="actionSelect('attach')"
        :class="attachClass"
    ><font-awesome-icon icon="fa-solid fa-add" /></button>
    <button
        v-if="baseNodes.indexOf(this.node.id) !== -1"
        @click="actionSelect('detach')"
        :class="detachClass"
    ><font-awesome-icon icon="fa-solid fa-minus" /></button>
    <button
        @click="handleDetachOthers()"
        :class="detachOthersClass"
    ><font-awesome-icon icon="fa-solid fa-angle-double-right" /></button>
    <button @click="appendNode()"><font-awesome-icon icon="fa-solid fa-puzzle-piece" /></button>
  </div>
</template>

<script>

import {library} from "@fortawesome/fontawesome-svg-core";
import {faSquareCheck, faPuzzlePiece, faBan, faAdd, faAngleDoubleRight, faMinus} from "@fortawesome/free-solid-svg-icons";
import UrlInfo from "@/components/link/info/UrlInfo";
import NodeRelationships from "@/components/link/info/NodeRelationships";

library.add(faSquareCheck)
library.add(faPuzzlePiece)
library.add(faBan)
library.add(faAdd)
library.add(faMinus)
library.add(faAngleDoubleRight)

export default {
  name: "NodeDisplay",
  components: {NodeRelationships, UrlInfo},
  props: ['node', 'foregroundColor', 'backgroundColor'],
  data() {
    return {
      ignoreRender: ['id', 'uid'],
      data: {},
        baseNodes: [],
      relationships: null,
      attachClicked: false,
      detachClicked: false,
      detachOthersClicked: false,
      ignoreClicked: false,
      selectedRelationship: null,
      ignoreClass: '',
      attachClass: '',
      detachClass: '',
      detachOthersClass: '',
    }
  },
  methods: {
    appendNode() {
      let nodeIndex = this.baseNodes.indexOf(this.node.id)
      if (nodeIndex === -1) {
        this.baseNodes.push('+' + this.node.id)
      } else {
        this.baseNodes[nodeIndex] = '+' + this.baseNodes[nodeIndex]
      }
      this.reroute()
    },
    toggleRelationship(relationship) {
      if (this.selectedRelationship === relationship) {
        this.selectedRelationship = null
        return
      }
      this.selectedRelationship = relationship
    },
    actionSelect(name) {
      this[name+'Clicked'] = !this[name+'Clicked']
      this[name+'Class'] = (this[name+'Clicked'])?'active':''
    },
    commitClicked() {
      if (this.ignoreClicked) {
        this.handleIgnored()
      }
      if (this.detachOthersClicked) {
        this.handleDetachOthers()
      } else if (this.attachClicked) {
        this.handleAttach()
      } else if (this.detachClicked) {
        this.handleDetach()
      }
    },
    handleIgnored() {
      const options = {
        method: 'POST',
        body: JSON.stringify({id: this.node.id, ignore: true}),
        headers: {
          'Content-Type': 'application/json'
        }
      }
      fetch('https://external.trading.10n.io/graph/set_ignore_node', options).then(this.$emit('reload'))

    },
    handleDetach() {
      const i = this.baseNodes.indexOf(this.node.id)
      if (i > -1) {
        delete this.baseNodes[i]
      }
      this.reroute()
    },
    handleDetachOthers() {

      this.baseNodes = [this.node.id]
      this.reroute()
    },
    handleAttach() {
      const i = this.baseNodes.indexOf(this.node.id)
      if (i === -1) {
        this.baseNodes.push(this.node.id)
      }
      this.reroute()
    },
    reroute(action) {
      let newRoute = {
        name: 'relationships',
        params: {
          symbol: this.$route.params.symbol,
          relationships: this.baseNodes.join(',')
        }
      }
      if (action) {
        newRoute.params.action = action
      }
      this.$router.push(newRoute)
    },
    getFilteredProperties(data) {
      let filtered = {}
      for (let i in data) {
        if (this.ignoreRender.indexOf(i) === -1) {
          filtered[i] = data[i]
        }
      }
      return filtered
    },
    async getRelationships() {
      const response = await fetch('https://external.trading.10n.io/company/relationship_type?id=' + this.node.id)
      this.relationships = await response.json()
    }
  },
  mounted() {
    this.data = this.getFilteredProperties(this.node.properties)
    this.getRelationships()
    if (this.$route.params.relationships) {
      this.baseNodes = this.$route.params.relationships.split(',')
      this.baseNodes = this.baseNodes.map(n => parseInt(n))
    }
  }
}
</script>

<style scoped>
.info-container {
  padding: 5px;
  overflow: scroll;
}
  dl {
    word-break: break-all;
  }
  dt, td:first-child {
    font-size: 0.9em;
    color: #D4E0EC;
  }
  dd {
    color: #fff;
    margin-left: 10px;
  }

  table {
    width: 100%;
  }
  td:last-child {
    text-align: right;
  }
</style>
