<template>
  <company-stats :symbol="$route.params.symbol" :key="$route.params.symbol" />
</template>

<script>
import CompanyStats from "@/components/CompanyStats";
export default {
  name: "StatsView",
  components: {CompanyStats},
}
</script>

<style scoped>

</style>
