<template>
  <flag-list :symbol="symbol" :key="symbol" />
</template>

<script>
import FlagList from "@/components/FlagList";
export default {
  name: "FlagView",
  components: {FlagList},
  props: ['symbol']
}
</script>

<style scoped>

</style>
