<template>
  <h1>{{ symbol }} compared To {{ industry }}</h1>
  <spinner v-if="showSpinner"/>  <table>
    <thead>
    <tr>
      <th>{{ symbol }}</th>
      <th>Stat</th>
      <th>Average</th>
      <th>Min</th>
      <th>Max</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="stat in processedIndustryComparisonStats" :key="stat.name">
      <td>{{ stat.sec }}</td>
      <td>{{ stat.name }}</td>
      <td>{{ stat.avg }}</td>
      <td>{{ stat.min }}</td>
      <td>{{ stat.max }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import Spinner from "@/components/Spinner";

export default {
  name: "IndustryComparison",
  components: {Spinner},
  props: ['symbol'],
  data() {
    return {
      industryComparisonStats: [],
      showSpinner: true,
      industry: '',
      industryConfiguration: {
        assets: {name: 'Assets', type: 'currency', precision: 1},
        liabilities: {name: 'Liabilities', type: 'currency', precision: 1},
        book_value: {name: 'Book Value', type: 'currency', precision: 1},
        book_value_per_share: {name: 'Book Value Per Share', type: 'currency', precision: 2},
        al_ratio: {name: 'Assets / Liabilities Ratio', type: 'number', precision: 2},
        cash: {name: 'Cash', type: 'currency', precision: 0},
        net_sales_revenue: {name: 'Net Sales Revenue', type: 'currency', precision: 0},
        gross_profit: {name: 'Gross profit', type: 'currency', precision: 0},
        profit_loss: {name: 'Profit / Loss', type: 'currency', precision: 0},
        revenues: {name: 'Revenue', type: 'currency', precision: 0},
        eps: {name: 'EPS', type: 'currency', precision: 2},
        estEarnings: {name: 'Estimated Earnings', type: 'currency', precision: 0},
        close: {name: 'Closing Price', type: 'currency', precision: 2},
        beta: {name: 'Beta', type: 'number', precision: 2},
        pe: {name: 'Price / Earnings', type: 'number', precision: 2},
        cmf: {name: 'Chaikin Money Flow', type: 'number', precision: 5},
        momentum: {name: 'Momentum', type: 'number', precision: 5},
        rsi: {name: 'Relative Strength index', type: 'number', precision: 2},
        price_acceleration: {name: 'Price Acceleration', type: 'number', precision: 5},
        bbands_spread: {name: 'Bollinger Bands Spread', type: 'number', precision: 2},
        close_low_spread: {name: 'Close / 52 Week Low Spread', type: 'number', precision: 2},
        close_high_spread: {name: '52 Week High / Close Spread', type: 'number', precision: 2},
        days_to_cover: {name: 'Days To Cover', type: 'number', precision: 0},
        short_percent_of_float: {name: 'Short Percentage of Float', type: 'number', precision: 2},
        short_interest_momentum_ratio: {name: 'Short Interest Momentum', type: 'number', precision: 5},

        cost_of_goods_sold: {name: 'Cost of Goods Sold', type: 'currency', precision: 0},
        net_income: {name: 'Net Income', type: 'currency', precision: 0},
        operating_income: {name: 'Operating Income', type: 'currency', precision: 0},
        income_taxes_paid: {name: 'Income Taxes Paid', type: 'currency', precision: 0},
        public_float: {name: 'Public Float', type: 'currency', precision: 0},
        outstanding_shares: {name: 'Outstanding Shares', type: 'number', precision: 0},
        stockholder_equity: {name: 'Stockholder Equity', type: 'currency', precision: 0},
        depreciation: {name: 'Depreciation', type: 'currency', precision: 0},
        high: {name: 'High', type: 'currency', precision: 2},
        high52: {name: '52 Week High', type: 'currency', precision: 2},
        low: {name: 'Low', type: 'currency', precision: 2},
        low52: {name: '52 Week Low', type: 'currency', precision: 2},
        totalVolume: {name: 'Total Volume', type: 'number', precision: 2},

        averageVolume: {name: 'Average Volume', type: 'number', precision: 2},
        interest_expense: {name: 'Interest Expense', type: 'currency', precision: 0},
        preferred_stock: {name: 'Preferred Stock', type: 'currency', precision: 0},
        issued_shares: {name: 'Issued Shares', type: 'number', precision: 0},
        sales_expenses: {name: 'Sales Expenses', type: 'currency', precision: 0},
        equity: {name: 'Equity', type: 'currency', precision: 0},
        wages_salaries: {name: 'Wages & Salaries', type: 'currency', precision: 0},
      },
      processedIndustryComparisonStats: {}
    }
  },
  methods: {
    async getStats() {
      this.showSpinner = true
      const request = await fetch('https://external.trading.10n.io/company/industry?symbol=' + this.symbol)
      const json = await request.json()
      this.industry = json.description
      this.industryComparisonStats = json.comparison
      this.processedIndustryComparisonStats = {}
      for (let i in this.industryConfiguration) {
        if (this.industryComparisonStats[i]) {
          const attrs = ['avg', 'min', 'max', 'sec']
          for (let v in attrs) {
            const attr = attrs[v]
            if (this.industryComparisonStats[i][attr] === null) {
              continue
            }
            if (this.industryConfiguration[i].type === 'currency') {
              let val = this.$dividify(this.industryComparisonStats[i][attr])
              this.industryComparisonStats[i][attr] = val[0].toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: this.industryConfiguration[i].precision,
                minimumFractionDigits: 0
              }) + val[1]
            } else if (this.industryConfiguration[i].type === 'number') {
              let val = this.$dividify(this.industryComparisonStats[i][attr], this.industryConfiguration[i].precision)
              this.industryComparisonStats[i][attr] = val[0] + val[1]
            }
            this.industryComparisonStats[i].name = this.industryConfiguration[i].name
          }
          this.processedIndustryComparisonStats[i] = this.industryComparisonStats[i]
        }
      }
      this.showSpinner = false
    }
  },
  created() {
    this.getStats()
  }
}
</script>

<style scoped>

table {
  width: 100%;
  font-size: 0.9em;
  border-collapse: collapse;
}

table > tbody > tr:nth-of-type(odd) {
  background-color: #0B2F51;
}

th {
  position: sticky;
  top:200px;
  background-color: #0a2036;
}

td {
  padding: 3px;
}

h1 {
  font-size: 0.9em;
  text-align: center;
  position: sticky;
  top: 170px;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #0a2036;
}
</style>
