import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import Plugin from "@/lib/Plugin"
import '@vueform/toggle/themes/default.css'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const app = createApp(App)
app.component("font-awesome-icon", FontAwesomeIcon)
app.use(router)
app.use(new Plugin)
app.component()
app.mount('#app')
