<template>
  <ul>
    <li>
      <router-link :to="{name: 'chart', params: {symbol: $route.params.symbol}}">Chart</router-link>
    </li>
    <li>
      <router-link :to="{name: 'financial', params: {symbol: $route.params.symbol}}">Financial</router-link>
    </li>
    <li>
      <router-link :to="{name: 'news', params: {symbol: $route.params.symbol}}">News</router-link>
    </li>
    <li>
      <router-link :to="{name: 'flags', params: {symbol: $route.params.symbol}}">Flags</router-link>
    </li>
    <li>
      <router-link :to="{name: 'stats', params: {symbol: $route.params.symbol}}">Stats</router-link>
    </li>
    <li>
      <router-link :to="{name: 'industry', params: {symbol: $route.params.symbol}}">Industry</router-link>
    </li>
    <li>
      <router-link :to="{name: 'heldby', params: {symbol: $route.params.symbol}}">Ownership</router-link>
    </li>
    <li>
      <router-link :to="{name: 'technical', params: {symbol: $route.params.symbol}}">Technical</router-link>
    </li>
    <li>
      <router-link :to="{name: 'sec', params: {symbol: $route.params.symbol}}">SEC Filings</router-link>
    </li>
    <li>
      <router-link :to="{name: 'links', params: {symbol: $route.params.symbol}}">Links</router-link>
    </li>
    <li>
      <router-link :to="{name: 'about', params: {symbol: $route.params.symbol}}">About</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "SecurityNavigation"
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 3px 0 0;
  padding: 0;
  text-align: center;
  width: 100%;
}
li {
  display: inline-block;
  margin-right: 12px;
  margin-top: 6px;
  font-size:0.96em;
  white-space: nowrap;
}

</style>
