<template>
  <div>
    <input v-if="type === 'ticker'" placeholder="Search for ticker..." v-model="search" v-on:keyup="captureNavigation">
    <input v-if="type === 'all'" placeholder="Search everywhere..." v-model="search" v-on:keyup="captureNavigation">
    <button @click="type = 'ticker'" v-if="type === 'all'"><font-awesome-icon icon="fa-solid fa-chart-line" /></button>
    <button @click="type = 'all'" v-if="type === 'ticker'"><font-awesome-icon icon="fa-solid fa-globe" /></button>
    <ul>
      <li v-for="(detail, ticker) in tickers" :key="ticker" @click="navigate(ticker)">
        {{ ticker }}
        - {{new Number(detail.lastTrade).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        precision: 3
      })}}
        - {{detail.companyName}}
      </li>
    </ul>
  </div>
</template>

<script>

import {library} from "@fortawesome/fontawesome-svg-core";
import {faChartLine, faGlobe} from "@fortawesome/free-solid-svg-icons";

library.add(faChartLine)
library.add(faGlobe)


export default {
  name: "SearchCompanies",
  data() {
    return {
      type: 'ticker',
      search: '',
      typeDelay: 0,
      tickers: {}
    }
  },
  methods: {
    captureNavigation(key) {
      if (key.code === 'Enter') {
        if (this.type === 'ticker') {
          this.navigate(this.search.toUpperCase())
          return
        }
        this.$router.push({name: 'search', params: {search: this.search}})
      }
    },
    navigate(symbol) {
      let name = 'chart'
      if (this.$route.name !== 'home') {
        name = this.$route.name
      }
      this.$router.push({name: name, params: {symbol: symbol}})
      this.tickers = {}
      this.search = ''
    },
    async capture() {
      if (!this.search || this.type === 'all') {
        this.tickers = {}
        return
      }
      const response = await fetch('https://external.trading.10n.io/search/company?name=' + this.search)
      const tickers = await response.json()
      this.tickers = {}
      for (let i in tickers) {
        this.tickers[tickers[i].id] = tickers[i]
      }
    }
  },
  watch: {
    search: function(n) {
      if (this.type === 'ticker') {
        this.search = n.toUpperCase()
      }
      this.typeDelay++
      setTimeout(() => {
        this.typeDelay--
        if (this.typeDelay === 0) {
          this.capture()
        }
      }, 500)
    }
  }
}
</script>

<style scoped>

  input {
    width: 87%;
    padding-top: 6px;
    padding-bottom: 6px;
    border: 3px solid white;
    border-radius: 3px;
    box-sizing: border-box;
  }
  button {
    width: 10%;
    margin-left: 2%;
    padding: 7px;
  }

  ul {
    position: fixed;
    list-style: none;
    margin: 0;
    padding: 0 0 0 12px;
    left: 0;
    right: 0;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
  }
  li {

    background-color: #293e54;
    padding: 6px;
    margin-right: 12px;
  }
</style>
