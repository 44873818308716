<template>
  <div>
    <ul v-if="displayLinks" class="links-container bottom-feeder has-background-color" :class="linksContainer?'links-container-active':''">
      <li v-for="node in store.state.selectedLinks" :key="node.id">
        <span class="trash" @click.stop="removeSelectedLink(node)"><font-awesome-icon icon="fa-solid fa-trash" /></span>
        {{$arrayToString(node.labels)}}: {{$nodeToString(node)}}
      </li>
      <li class="find-relationships" @click="routeGraphLinkDiscovery">
        Find Relationships <font-awesome-icon icon="fa-solid fa-play" />
      </li>
    </ul>
    <ul class="options-container bottom-feeder has-background-color">
      <li class="has-background-color" @click.stop="linksContainer = !linksContainer" v-if="displayLinks">
        <font-awesome-icon icon="fa-solid fa-arrows-left-right-to-line" />
        <span class="link-count-banner" v-if="displayLinks">{{Object.keys(store.state.selectedLinks).length}}</span>

      </li>
      <li v-if="back.length > 0" class="has-background-color" @click.stop="relationshipsBack">
        <font-awesome-icon icon="fa-solid fa-angles-left" />
      </li>
      <li class="scroll-up has-background-color" @click.stop="scrollToTop">
        <font-awesome-icon icon="fa-solid fa-arrows-up-to-line"/>
        <!--      <i class="fa-solid fa-arrows-up-to-line"></i>-->
      </li>
    </ul>
    <div class="containing-container" ref="containerView" id="linksViewContainer">
      <link-view
          :symbol="$route.params.symbol"
          :key="$route.params.symbol"
          @link-selected="linkSelected"
      />
    </div>
  </div>
</template>

<script>
import LinkView from "@/components/LinkView";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faArrowsUpToLine, faArrowsLeftRightToLine, faAnglesLeft, faTrash, faPlay} from "@fortawesome/free-solid-svg-icons";
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

library.add(faArrowsUpToLine)
library.add(faArrowsLeftRightToLine)
library.add(faAnglesLeft)
library.add(faTrash)
library.add(faPlay)

export default {
  name: "LinksView",
  components: {LinkView},
  data() {
    return {
      store: new Vuex.Store({
        state: {
          selectedLinks: {}
        },
        mutations: {
          linkSelected(state, payload) {
            if (payload.selected) {
              state.selectedLinks[payload.node.id] = payload.node
            } else {
              delete state.selectedLinks[payload.node.id]
            }
          }
        },
        plugins: [
          new VuexPersist({
            key: 'links',
            storage: window.localStorage
          }).plugin
        ]
      }),
      displayLinks: false,
      back: [],
      selectedLinks: {},
      linksContainer: false
    }
  },
  methods: {
    routeGraphLinkDiscovery() {
      this.$router.push({
        name: 'relationships',
        params: {
          symbol: this.$route.params.symbol,
          relationships: Object.keys(this.store.state.selectedLinks).join(',')
        }
      })
    },
    removeSelectedLink(node) {
      this.store.commit('linkSelected', {node, selected: false})
      this.displayLinks =  Object.keys(this.store.state.selectedLinks).length > 0
    },
    relationshipsBack() {
      if (this.back.length > 0) {
        this.back.pop()
        this.$router.push({
          name: 'links',
          params: {
            symbol: this.$route.params.symbol,
            relationships: this.back.join(',')
          }
        })
      }
    },
    linkSelected(node, selected) {
      this.store.commit('linkSelected', {node, selected})
      this.displayLinks =  Object.keys(this.store.state.selectedLinks).length > 0
    },
    scrollToTop() {
      document.getElementById('linksViewContainer').scroll({top: 0, behavior: 'smooth'})
    },
    processBack(){
      let back = []
      if (this.$route.params && this.$route.params.relationships) {
        back = this.$route.params.relationships.split(',')
      }
      this.back = back
    }
  },
  watch: {
    $route: function() {
      this.processBack()
    }
  },
  mounted() {
    this.processBack()
    this.displayLinks =  Object.keys(this.store.state.selectedLinks).length > 0
  }
}
</script>

<style scoped>

.trash {
  font-size: 0.8em;
}

.options-container {
  text-align: right;
  z-index: 10000;
  border-radius: 3px;
  font-size: 1.4em;
  /*font-weight: bold;*/
  margin-right: 5px;
  color: #b0c1d3;
  list-style: none;
  padding: 5px;
}

.bottom-feeder {
  position: absolute;
  right: 0;
  /*bottom: 100px;*/
  top: 300px;
}

.options-container li {
  padding: 7px;
  border-radius: 3px;
}

.links-container li {
  margin: 0;
  padding: 3px 3px 6px 3px;
  white-space: nowrap;
  transition: all 500ms;
}
.links-container {
  z-index: 9999;
  font-size: 1em;
  list-style: none;
  max-width: 0;
  padding: 1px;
  overflow: hidden;
  transition: all 500ms;
  border: 1px solid #0A2036;
}

.links-container-active li {
  padding-right: 60px;
}
.links-container-active {
  max-width: 350px;
  padding: 5px;
  border: 1px solid #B7BBC0;
  border-radius: 5px;
}

.containing-container {
  overflow-y: scroll;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

div {
  min-height: 100%;
}

.link-count-banner {
  font-size: 12px;
  font-weight: bold;
  width: 17px;
  height: 17px;
  aspect-ratio: 1/1;
  color: #52c526;
  position: absolute;
  text-align: center;
  right: 6px;
  margin-top: -3px;
  border: 1px solid #B7BBC0;
  background-color: #2C3E50;
  border-radius: 50%;
}

.find-relationships {
  text-align: right;
  border-top: 1px solid #2C3E50;
  background-color: #0d2b48;
}
</style>
