<template>
  <div>
    <div class="spinner" v-if="data === null">
      <spinner/>
    </div>
    <div class="node-container has-background-color" v-if="selectedNode" >
    <node-display :node="selectedNode" :key="selectedNode.id" @reload="$emit('reload')"/>
    </div>
    <ul class="legend">
      <li
          v-for="(color, type) in colorAssignments"
          :key="type"
          :style="{backgroundColor: color, color: this.lightOrDark(color) === 'light' ? '#2f2e2e' : '#c0c0c0'}"
          @click="toggleTypeDisplay(type)"
      >
        {{ $labelToWords(type) }}
      </li>
    </ul>
    <div id="relationship-visualization-container" ref="visualization" :key="depth"></div>
    <div v-if="data && data.nodes && Object.keys(data.nodes).length < depthThreshold" class="depth-selector">
      <span v-if="depth > 1"><font-awesome-icon icon="fa-solid fa-minus" @click="depth--"/></span>
      <span class="depth">Depth {{depth}}</span>
      <span><font-awesome-icon icon="fa-solid fa-plus" @click="depth++"/></span>
    </div>
  </div>
</template>

<script>

import {library} from "@fortawesome/fontawesome-svg-core";
import {faPlus, faMinus} from "@fortawesome/free-solid-svg-icons";

library.add(faPlus, faMinus)
import Spinner from "@/components/Spinner"
import {Network} from "vis-network/peer/esm/vis-network";
import {DataSet} from "vis-data/peer/esm/vis-data"
import NodeDisplay from "@/components/link/NodeDisplay";

export default {
  name: "RelationshipVisualization",
  components: {NodeDisplay, Spinner},
  props: ['relationships'],
  data() {
    return {
      /* https://paletton.com/#uid=73-210kkbm61EEmaIs-tXfHTk9t */
      /* https://paletton.com/#uid=73-210kleng1AGeaBuAu1hlTaa8 */
      /* https://paletton.com/#uid=73-1w0kcyqy03X24PztmkkjGifo */
      /* https://paletton.com/#uid=73-2k0kdaCf00++3rU1mnrSB4l1 */
      colors: [],
      'colorOptions': '@color-primary-0: #7397BA;\t/* Main Primary color */\n' +
          '@color-primary-1: #FFFFFF;\n' +
          '@color-primary-2: #D4E0EB;\n' +
          '@color-primary-3: #336290;\n' +
          '@color-primary-4: #063B6D;\n' +
          '\n' +
          '@color-secondary-1-0: #FFB596;\t/* Main Secondary color (1) */\n' +
          '@color-secondary-1-1: #FFFFFF;\n' +
          '@color-secondary-1-2: #FFECE4;\n' +
          '@color-secondary-1-3: #DE7143;\n' +
          '@color-secondary-1-4: #A83200;\n' +
          '\n' +
          '@color-secondary-2-0: #71C0A0;\t/* Main Secondary color (2) */\n' +
          '@color-secondary-2-1: #FFFFFF;\n' +
          '@color-secondary-2-2: #D4EDE3;\n' +
          '@color-secondary-2-3: #2E976D;\n' +
          '@color-secondary-2-4: #007244;\n' +
          '\n' +
          '@color-complement-0: #FFD496;\t/* Main Complement color */\n' +
          '@color-complement-1: #FFFFFF;\n' +
          '@color-complement-2: #FFF4E4;\n' +
          '@color-complement-3: #DE9F43;\n' +
          '@color-complement-4: #A86300;',
      data: null,
      network: null,
      selectedNode: null,
      baseNodes: [],
      additionalRelationships: [],
      depth: 1,
      lastRun: 1,
      depthThreshold: 15,
      widthConstraint: 200,
      colorAssignments: {},
      config: null,
      oldColors: {}
    }
  },
  methods: {
    toggleTypeDisplay(type) {
      if (this.oldColors[type]) {
        this.colorAssignments[type] = this.oldColors[type]
        delete this.oldColors[type]
        for (let i in this.data.nodes) {
          if (this.data.nodes[i].type  === type) {
            this.config.nodes.update([{id: i, hidden: false}])
          }
        }
        return
      }
      this.oldColors[type] = this.colorAssignments[type]
      this.colorAssignments[type] = '#eee'
      for (let i in this.data.nodes) {
        if (this.data.nodes[i].type  === type) {
          this.config.nodes.update([{id: i, hidden: true}])
        }
      }
    },
    getColor(type, darken) {
      if (!this.colorAssignments[type]) {
        if (Object.keys(this.colorAssignments).length >= Object.keys(this.colors).length) {
          // all the colors have been used up.  Now we go to gray scale.
            this.colorAssignments[type] = '#eeeeee'
            const darkenAmount =  1 - ((Object.keys(this.colors).length / Object.keys(this.colorAssignments).length) * 1.1)
            this.colorAssignments[type] = this.getColor(type, darkenAmount)
            return this.colorAssignments[type]
        } else {
          this.colorAssignments[type] = this.colors[Object.keys(this.colorAssignments).length]
        }
      }
      let color = this.colorAssignments[type]
      if (darken) {
        color = color.replace('#', '')
        const parts = /(\w{2})(\w{2})(\w{2})/.exec(color)
        let rgb = [
            parseInt(parts[1], 16),
            parseInt(parts[2], 16),
            parseInt(parts[3], 16),
        ]
        rgb[0] = rgb[0] - parseInt(rgb[0] * darken)
        rgb[1] = rgb[1] - parseInt(rgb[1] * darken)
        rgb[2] = rgb[2] - parseInt(rgb[2] * darken)
        color = '#' + rgb[0].toString(16) + rgb[1].toString(16) + rgb[2].toString(16)
      }
      return color
    },
    async getNodes() {
      this.config = this.getDefaultConfig()
      let relationships = []
      this.additionalRelationships = []
      for (let i in this.relationships) {
        const relationship = this.relationships[i].replace('+', '')
        relationships.push(relationship)
        if (this.relationships[i] !== relationship) {
          this.additionalRelationships.push(relationship)
        }
      }
      let url = 'https://external.trading.10n.io/company/relationships?ids=' + relationships.join(',') + '&depth=' + this.depth

      // url += '&XDEBUG_SESSION_START=1'
      const request = await fetch(url)
      this.data = await request.json()
      for (let n in this.data.nodes) {
          this.data.nodes[n].type = this.$arrayToString(this.data.nodes[n].labels)
      }
      for (let i in this.additionalRelationships) {
        url = 'https://external.trading.10n.io/company/relationships?ids=' + this.additionalRelationships[i]
        const request = await fetch(url)
        const data = await request.json()
        for (let n in data.nodes) {
          if (!this.data.nodes[n]) {
            this.data.nodes[n] = data.nodes[n]
            this.data.nodes[n].type = this.$arrayToString(this.data.nodes[n].labels)
          }
        }
        for (let n in data.connections) {
          if (!this.data.connections[n]) {
            this.data.connections[n] = data.connections[n]
          }
        }
      }

      this.processData()
    },
    processData() {
      for (let i in this.data.nodes) {
        const node = this.data.nodes[i]
        let label = this.$nodeToString(node, true)
        const type = this.$arrayToString(node.labels)
        if (label.length > 32) {
          label = label.substr(0, 32)
        }

        const backgroundColor = this.getColor(type);
        let nodeData = {
          id: i,
          shape: 'circle',
          label: label,
          color: {
            background: backgroundColor,
            border: this.baseNodes.indexOf(node.id) === -1 ? this.getColor(type, 0.2) : 'red'
          },
          widthConstraint: {
            minimum: this.widthConstraint,
            maximum: this.widthConstraint
          },
          heightConstraint: {
            minimum: this.widthConstraint,
            maximum: this.widthConstraint
          },
          font: {
            color: this.lightOrDark(backgroundColor) === 'light' ? '#2f2e2e' : '#c0c0c0',
          }

        }
        this.config.nodes.add(nodeData)
      }
      for (let i in this.data.connections) {
        const connection = this.data.connections[i]
        const edge = {
          to: connection.to,
          from: connection.from,
          label: this.$relationshipToWords(connection.type),
          font: {
            face: 'Open Sans, Helvetica, Arial, sans-serif',
            bold: '14px arial red',
            size: 30,
            align: "middle"
          }
        }
        this.config.edges.add(edge)
      }
      this.network = new Network(this.$refs.visualization, this.config, this.options);
      this.network.on("deselectNode", () => {
        this.selectedNode = null
      })
      this.network.on("selectNode", (params) => {
        this.selectedNode = this.data.nodes[params.nodes[0]]
      });
    },
    calculateHsp(color) {
      let r, g, b, hsp;

      // Check the format of the color, HEX or RGB?
      color = +("0x" + color.slice(1).replace(
          color.length < 5 && /./g, '$&$&'));

      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
      hsp = Math.sqrt(
          0.299 * (r * r) +
          0.587 * (g * g) +
          0.114 * (b * b)
      );
      return hsp
    },
    lightOrDark(color) {
      const hsp = this.calculateHsp(color)
      // Variables for red, green, blue values
      if (hsp > 127.5) {
        return 'light';
      } else {
        return 'dark';
      }
    },
    getDefaultConfig() {
      return {
        nodes: new DataSet(),
        edges: new DataSet(),
        options: {
          "edges": {
            "smooth": false,
            color: '#888',
            font: {
              face: 'Open Sans, Helvetica, Arial, sans-serif',
              size: 170,
              align: "middle",
              background: '#f7f8fa'
            },
            width: 3
          },
          nodes: {
            font: {
              face: 'Open Sans, Helvetica, Arial, sans-serif',
              size: 34
            },
            borderWidth: 3
          },
          "physics": {
            "barnesHut": {
              "theta": 0.6,
              "gravitationalConstant": -28650,
              "centralGravity": 0,
              "springLength": 325,
              "springConstant": 0.36,
              "damping": 0.38,
              avoidOverlap: 1
            },
            "minVelocity": 3.5,
            "timestep": 0.1
          }
        },
      }
    }
  },
  watch: {
    depth: function() {
      this.getNodes()
    },
  },
  mounted() {
    this.baseNodes = this.$route.params.relationships.split(',').map(n => parseInt(n))
    const lines = this.colorOptions.split("\n")
    let colors = []
    for (let i in lines) {
      let parts = lines[i].split(': ')
      if (parts.length > 1) {
        colors.push(parts[1].split(';')[0])
      }
    }
    // colors = colors.reverse()
    const colorJump = [0, 5, 10, 15]
    for (let i = 0; i < 4; i++) {
      for (let j in colorJump) {

        const index = i + colorJump[j]
        const color = colors[index]
        const hsp = this.calculateHsp(color)
        if (hsp < 250) {
          this.colors.push(color)
        }
      }
    }
    this.getNodes()
  }
}
</script>

<style scoped>
.spinner {
  z-index: 20000;
  position: absolute;
  top: 400px;
  left: 50%;
}

#relationship-visualization-container {
  width: 100%;
  height: 600px;
}

.legend {
  z-index: 10000;
  position: absolute;
  list-style: none;
  padding: 0;
  font-size: 0.8em;
  right: 10px;
  border-radius: 5px;
  border: 1px solid #637991;
}

.legend li {
  padding: 3px 6px;
}

:deep(.vis-item .vis-item-overflow) {
  overflow: hidden;
}

.node-container {
  z-index: 10000;
  position: absolute;
  overflow: scroll;
  top: 220px;
  bottom: 100px;
  left: 0;
  border-radius: 5px;
  border: 1px solid #637991;
  max-width: 60%;
}

.depth-selector {
  position: absolute;
  z-index: 10000;
  bottom: 50px;
  right: 20px;
  color: black;
  border-radius: 3px;
  border: 1px solid black;
  background-color: white;
}

.depth-selector span {
  padding: 5px 5px 5px 5px
}
.depth-selector .depth {
  border-left: 1px solid black;
  border-right: 1px solid black;
  padding-right: 8px;
  padding-left: 5px;
  margin-right: 5px;
  margin-left: 5px;
}

.depth-selector .depth:nth-child(1) {
  margin-left: 0;
  border-left: none;
}

</style>
