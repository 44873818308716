<template>
  <table>
    <thead>
      <tr>
        <th>Form</th>
        <th>Type</th>
        <th>Accepted</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="filing in filings" :key="filing._id">
        <td>{{filing.form}}</td>
        <td>{{filing.title}}</td>
        <td>{{new Intl.DateTimeFormat('en-US', {dateStyle: 'short'}).format(new Date(filing.accepted_at))}}</td>
        <td><a :href="filing.url" target="_blank">View</a></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "SECFilings",
  props: ['symbol'],
  data() {
    return {
      filings: []
    }
  },
  methods: {
    async loadFilings() {
      const response = await fetch('https://external.trading.10n.io/company/sec?symbol=' + this.symbol)
      this.filings = await response.json()
    }
  },
  created() {
    this.loadFilings()
  }
}
</script>

<style scoped>
table {
  width: 100%;
  /*table-layout: fixed;*/
}

th {
  position: sticky;
  top:178px;
  background-color: #0a2036;
}
</style>
