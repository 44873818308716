export default class
{
    install(app) {
        app.config.globalProperties.$dividify = function (number, precision) {
            if (!precision) {
                precision = 2
            }
            const isNegative = number < 0
            if (isNegative) {
                number *= -1
            }
            const presets = [
                [1000000000000, 1000000000000, 't'],
                [1000000000, 1000000000, 'b'],
                [1000000, 1000000, 'm'],
                [10000, 1000, 'k'],
            ]
            for (let i in presets) {
                const qualifier = presets[i][0]
                const divisor = presets[i][1]
                const indicator = presets[i][2]

                if (number >= qualifier) {
                    number = (number / divisor)
                    if (precision) {
                        precision = Math.pow(10, precision)

                        number = parseInt(number * precision) / precision
                    }
                    if (isNegative) {
                        number *= -1
                    }
                    return [number, indicator]
                }
            }
            if (precision) {
                precision = Math.pow(10, precision)
                number = parseInt(number * precision) / precision
            }
            return [number, '']
        }

        app.config.globalProperties.$relationshipToWords = function(rel) {
            const parts = rel.split('_').map(r => r.toLowerCase().replace(/\b([a-z])/gi, function (c) {
                return c.toUpperCase()
            }));
            return parts.join(' ')
        }
        app.config.globalProperties.$labelToWords = function(rel) {
            const regex = /([A-Z][a-z])/g
            let parts = rel.replaceAll(regex, '_$1').trim()
            parts = parts.split('_').map(r => r.toLowerCase().replace(/\b([a-z])/gi, function (c) {
                return c.toUpperCase()
            }));
            return parts.join(' ')
        }
        app.config.globalProperties.$objectToString = function(obj, ignores) {
            if (!ignores) {
                ignores = []
            }
            const objType = typeof obj
            if (objType === "array") {
                let r = []
                for (let i in obj) {
                    r.push(app.config.globalProperties.$objectToString(obj[i], ignores))
                }
                return r.join("<br>")
            }
            if (objType === "object") {
                let r = ''
                const objKeys = Object.keys(obj)
                for (let k in objKeys) {
                    const key = objKeys[k]
                    if (ignores.indexOf(key) === -1) {
                        r += key + ": " + obj[key] + "<br>"
                    }
                }
                obj = r.trim()
            }
            return obj
        }
        app.config.globalProperties.$nodeToString = function(node, ignoreLabels) {
            if (!node || !node.properties) {
                return ''
            }
            const properties = node.properties
            if (properties.title) {
                return properties.title
            }
            if (properties.name) {
                return properties.name
            }
            if (properties.symbol) {
                return properties.symbol
            }
            const keysToIgnore = ['uid', 'id']
            if (ignoreLabels) {
                let nProps = []
                for (let i in properties) {
                    if (keysToIgnore.indexOf(i) === -1) {
                        nProps.push(properties[i])
                    }
                }
                return app.config.globalProperties.$arrayToString(nProps)
            }
            return app.config.globalProperties.$objectToString(node.properties, keysToIgnore)
        }
        app.config.globalProperties.$arrayToString = function(ar) {
            const arType = typeof ar
            if (arType === "object" || arType === "array") {
                const vs = Object.values(ar)
                ar = vs.map(a => String(a).replace('NER_', '')).join(", ")
            }
            return ar
        }
    }

}
