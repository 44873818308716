<template>
  <div>
    <relationship-visualization :relationships="relationships.split(',')" :key="relationships + indexView" @reload="indexView++" />
  </div>
</template>

<script>
import RelationshipVisualization from "@/components/RelationshipVisualization";
export default {
  name: "RelationshipsView",
  components: {RelationshipVisualization},
  props: ['relationships'],
  data() {
    return {
      indexView: 0
    }
  }
}
</script>

<style scoped>
  div {
    background-color: #f7f8fa;
  }
</style>
