<template>
  <about-company  :symbol="$route.params.symbol" :key="$route.params.symbol" />
</template>

<script>
import AboutCompany from "@/components/AboutCompany";
export default {
  name: "AboutView",
  components: {AboutCompany}
}
</script>

<style scoped>

</style>
