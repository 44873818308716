<template>
  <div>
    <candle-chart :symbol="$route.params.symbol" />
  </div>
</template>

<script>
import CandleChart from "@/components/charts/CandleChart";
export default {
  name: "ChartView",
  components: {CandleChart}
}
</script>

<style scoped>

</style>
