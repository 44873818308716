<template>
  <ul v-if="$route.params.symbol">
    <li>
      {{ $route.params.symbol.toUpperCase() }}
      - {{new Number(this.mark).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      precision: 3
    })}}
      - {{name}}</li>
  </ul>
</template>

<script>
export default {
  name: "CurrentSymbol",
  data() {
    return {
      name: '',
      mark: 0,
      timer: false
    }
  },
  methods: {
      async getSymbol() {
        if (this.$route.params.symbol) {
          const response = await fetch('https://external.trading.10n.io/search/company?symbol=' + this.$route.params.symbol)
          const result = await response.json()
          if (result.length > 0) {
            this.name = result[0].companyName
            this.mark = result[0].lastTrade
          }
        }
      }
  },
  mounted() {
    this.getSymbol()
    this.timer = setInterval(this.getSymbol, 30000)
  },
  watch: {
    '$route.params.symbol': {
      handler: function() {
        this.getSymbol()
      },
      deep: true,
      immediate: true
    }
  },
  unmounted() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }
}
</script>

<style scoped>
ul {
  margin: 9px 0 0;
  list-style: none;
  padding: 3px 6px 3px 6px;
  background-color: #293e54;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

</style>

