<template>
  <dl>
    <dt>URL</dt>
    <dd class="url" v-if="title"><a :href="url" target="_blank">{{title}}</a></dd>
    <dd class="url" v-else-if="url"><a :href="url" target="_blank">{{url}}</a></dd>
    <template v-else v-for="(value, name) in getProperties()" :key="name">
      <dt>{{name}}</dt>
      <dd>{{value}}</dd>
    </template>
  </dl>
</template>

<script>
export default {
  name: "UrlInfo",
  props: ['node'],
  data() {
    return {
      title: null,
      url: null
    }
  },
  methods: {
    getProperties() {
      const ignore = ['uid']
      let prop = {}
      for (let i in this.node.properties) {
        if (ignore.indexOf(i) === -1) {
          prop[i] = this.node.properties[i]
        }
      }
      return prop
    }
  },
  mounted() {
    if (this.node.properties.title) {
      this.title = this.node.properties.title
    }
    if (this.node.properties.url) {
      this.url = this.node.properties.url
    }
  }
}
</script>

<style scoped>
dt {
  font-size: 0.9em;
  color: #D4E0EC;
}
dd {
  margin-left: 0;
}
</style>
