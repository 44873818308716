<template>
  <div class="example">
    <div class="block">
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Spinner"
}
</script>

<style scoped>

.example {
  position: absolute;

  left: 50%;
  height: 1rem;
  width: 1rem;
  transform: translateX(-50%) translateY(-50%);
}
.container {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
}
.block {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
}
.block > .item {
  position: absolute;
  height: 1rem;
  width: 1rem;
  background: #fff;
  -webkit-animation: move 0.5s linear infinite;
  animation: move 0.5s linear infinite;
}
.block > .item:nth-of-type(1) {
  top: -1rem;
  left: -1rem;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.block > .item:nth-of-type(2) {
  top: -1rem;
  left: 0;
  -webkit-animation-delay: -0.0625s;
  animation-delay: -0.0625s;
}
.block > .item:nth-of-type(3) {
  top: -1rem;
  left: 1rem;
  -webkit-animation-delay: -0.125s;
  animation-delay: -0.125s;
}
.block > .item:nth-of-type(4) {
  top: 0;
  left: 1rem;
  -webkit-animation-delay: -0.1875s;
  animation-delay: -0.1875s;
}
.block > .item:nth-of-type(5) {
  top: 1rem;
  left: 1rem;
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}
.block > .item:nth-of-type(6) {
  top: 1rem;
  left: 0;
  -webkit-animation-delay: -0.3125s;
  animation-delay: -0.3125s;
}
.block > .item:nth-of-type(7) {
  top: 1rem;
  left: -1rem;
  -webkit-animation-delay: -0.375s;
  animation-delay: -0.375s;
}
.block > .item:nth-of-type(8) {
  top: 0;
  left: -1rem;
  -webkit-animation-delay: -0.4375s;
  animation-delay: -0.4375s;
}
@-webkit-keyframes move {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes move {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

</style>
