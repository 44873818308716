<template>
  <div>
    <template v-if="this.actualData.metrics && this.actualData.metrics.length > 1">
      <button
          v-for="metric in this.actualData.metrics"
          :key="metric"
          @click="this.metric = metric"
          :class="this.metric === metric ? 'selected' : ''"
      >{{metric}}</button>
    </template>
    <table>
      <thead>
      <tr>
        <th>Description</th>
        <th>Total</th>
        <th>By Share</th>
        <th v-if="hasHistories">Change</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="values in calculatedRows" :key="values[0]">
        <tr @click="showHistory[values[0]] = !showHistory[values[0]]">
          <td>
            <span>{{ values[0] }}</span>
            <template v-if="hasHistory(values[0])">
              <span class="additional-data" v-show="!showHistory[values[0]]"> ➕</span>
              <span class="additional-data" v-show="showHistory[values[0]]"> ➖</span>
            </template>
          </td>
          <td class="nowrap">{{ values[1] }}</td>
          <td>{{ values[2] }}</td>
          <td v-if="hasHistories">{{ calculateChange(values[0], '%') }}</td>
        </tr>
        <template v-if="hasHistory(values[0])">
          <tr
              v-for="(history, context) in histories[values[0]].contexts"
              :key="context + values[0]"
              v-show="this.showHistory[values[0]]"
              class="context"
          >
            <td colspan="3">
              {{ context }}
            </td>
            <td class="nowrap">{{ $dividify(history, 2).join('') }}</td>
          </tr>
        </template>
      </template>
      </tbody>
    </table>
    <div class="debits-and-credits-selector">
      <button v-if="!loadDebitsAndCredits" @click="loadDebitsAndCredits = !loadDebitsAndCredits">Load Debits/Credits</button>
      <button v-if="loadDebitsAndCredits" @click="loadDebitsAndCredits = !loadDebitsAndCredits">Hide Debits/Credits</button>
    </div>
    <credits-debits :symbol="symbol" v-if="loadDebitsAndCredits"/>
  </div>
</template>

<script>
import CreditsDebits from "@/components/financial/CreditsDebits";

export default {
  name: "FinancialInformation",
  components: {CreditsDebits},
  props: ['symbol'],
  data() {
    return {
      loadDebitsAndCredits: false,
      quoteTimer: false,
      metric: 'USD',
      quote: {},
      showHistory: {},
      hasHistories: false,
      actualData: {},
      outstandingShares: null,
      calculatedRows: [],
      histories: {},
      dataRows: [
        'Assets',
        'Liabilities',
        'Book Value',
        'Price to Book',
        'Cash',
        'Market Cap',
        'EBITDA',
        'Net Sales Revenue',
        'Sales Expenses',
        'Cost of Goods Sold',
        'Equity',
        'Net Income',
        'Gross Profit',
        'Operating Income',
        'Revenues',
        'Profit/Loss',
        'Public Float',
        'Issued Shares',
        'Outstanding Shares',
        'Stockholder Equity',
        'Wages/Salaries',
      ],
      dataPoints: {
        'Assets': ['us-gaap.Assets', 'ifrs-full.Assets'],
        'Liabilities': ['us-gaap.Liabilities', 'ifrs-full.Liabilities'],
        'Cash': ['us-gaap.CashAndCashEquivalents', 'ifrs-full.CashAndCashEquivalents', 'us-gaap.CashAndCashEquivalentsAtCarryingValue', 'ifrs-full.CashAndCashEquivalentsAtCarryingValue',],
        'Market Cap': this.marketCap,
        'EBITDA': this.ebitda,
        'Book Value': this.bookValue,
        'Price to Book': this.priceToBook,
        'Net Sales Revenue': ['us-gaap.SalesRevenueNet', 'ifrs-full.SalesRevenueNet'],
        'Sales Expenses': ['us-gaap.SalesAndMarketingExpense', 'ifrs-full.SalesAndMarketingExpense'],
        'Cost of Goods Sold': ['us-gaap.CostOfGoodsSold', 'ifrs-full.CostOfGoodsSold'],
        'Equity': ['us-gaap.Equity', 'ifrs-full.Equity'],
        'Net Income': ['us-gaap.NetIncomeLoss', 'ifrs-full.NetIncomeLoss'],
        'Gross Profit': ['us-gaap.GrossProfit', 'ifrs-full.GrossProfit'],
        'Operating Income': ['us-gaap.OperatingIncomeLoss', 'ifrs-full.OperatingIncomeLoss'],
        'Revenues': ['us-gaap.Revenues', 'ifrs-full.Revenues'],
        'Income Taxes Paid': ['us-gaap.IncomeTaxesPaidNet', 'ifrs-full.IncomeTaxesPaidNet'],
        'Interest Expense': ['us-gaap.InterestExpense', 'ifrs-full.InterestExpense'],
        'Profit/Loss': ['us-gaap.ProfitLoss', 'ifrs-full.ProfitLoss'],
        'Preferred Shares': ['us-gaap:PreferredStockSharesOutstanding', 'ifrs-full.PreferredStockSharesOutstanding'],
        'Public Float': ['dei.EntityPublicFloat'],
        'Issued Shares': ['us-gaap.SharesIssued'],
        'Outstanding Shares': ['dei.EntityCommonStockSharesOutstanding', 'us-gaap.SharesOutstanding'],
        'Stockholder Equity': ['us-gaap.StockholdersEquity', 'ifrs-full.StockholdersEquity'],
        'Wages/Salaries': ['us-gaap.WagesAndSalaries', 'ifrs-full.WagesAndSalaries'],
        'Depreciation': ['us-gaap.Depreciation', 'ifrs-full.Depreciation']
      }
    }
  },
  methods: {
    hasHistory(name) {
      // console.log(this.histories[name], Object.values(this.histories[name].contexts).length)
      return this.histories[name] && Object.values(this.histories[name].contexts).length > 1
    },
    priceToBook() {
      if (!this.outstandingShares) {
        return 'N/A'
      }
      const bookValuePerShare = this.bookValue() / this.outstandingShares
      return bookValuePerShare / this.quote.mark
    },
    bookValue() {
      const assets = this.filterSource('Assets', true) ?? 0
      const liabilities = this.filterSource('Liabilities', true) ?? 0
      const preferredShares = this.filterSource('Preferred Shares', true) ?? 0
      const preferredEquity = preferredShares * this.quote.mark
      return assets - liabilities - preferredEquity
    },
    ebitda() {
      // .split('.')[0].replace(/\D/g, '') * 1;
      let value = this.filterSource('Net Income', true)

      let depreciation = this.filterSource('Depreciation', true)
      if (depreciation && depreciation !== 'NaN' && depreciation !== 'N/A') {
        // depreciation = depreciation.split('.')[0].replace(/\D/g, '') * 1
        value += depreciation
      }
      let incomeTaxes = this.filterSource('Income Taxes Paid', true)
      if (incomeTaxes && incomeTaxes !== 'NaN' && incomeTaxes !== 'N/A') {
        // incomeTaxes = incomeTaxes.split('.')[0].replace(/\D/g, '') * 1
        value += incomeTaxes
      }
      let interestExpense = this.filterSource('Interest Expense', true)
      if (interestExpense && interestExpense !== 'NaN' && interestExpense !== 'N/A') {
        // interestExpense = interestExpense.split('.')[0].replace(/\D/g, '') * 1
        value += interestExpense
      }
      return value
      // const divided = this.$dividify(value)
      // return (Math.round(divided[0] * 100) / 100) + divided[1]
    },
    calculateChange(name, suffix) {
      if (!this.histories[name]) {
        return ''
      }
      let values = Object.values(this.histories[name].contexts)
      if (values.length < 2) {
        return ''
      }
      let lastValue = values[0]
      let previousValue = values[1]
      if (previousValue === 0 || lastValue === 0) {
        return ''
      }
      let change = (Math.round(((lastValue - previousValue) / lastValue) * 10000) / 100)
      if (suffix) {
        change += suffix
      }
      return change
    },
    marketCap() {
      return this.outstandingShares * this.quote.mark
      // const r = this.$dividify(this.outstandingShares * this.quote.mark)
      // console.log(r, r[0])
      // const results = (Math.round(r[0] * 100) / 100) + '' + r[1]
      // console.log(results)
      // return results
    },
    filterSource(dataPointName, divisor, precision) {

      if (!divisor) {
        divisor = 1
      }
      if (typeof dataPointName !== 'object' && !this.dataPoints[dataPointName]) {
        return null
      }
      if (typeof this.dataPoints[dataPointName] === 'function') {
        if (divisor !== 1) {
          return null
        }
        return this.dataPoints[dataPointName]()
      }
      let options
      if (typeof dataPointName === 'object') {
        options = dataPointName
      } else {
        options = this.dataPoints[dataPointName]
      }
      for (let i in options) {
        if (this.actualData[options[i]]) {
          let number = this.actualData[options[i]] / divisor
          if (precision) {
            number = number.toFixed(precision)
          }
          this.histories[dataPointName] = {source: options[i], contexts: {}}
          for (let h in this.actualData.history[options[i]] ?? []) {
            this.histories[dataPointName].contexts[h] = this.actualData.history[options[i]][h]
            this.hasHistories = true
          }
          return number
        }
      }
      return null
    },
    async loadFinancialInformation() {
      this.calculatedRows = []
      this.histories = {}
      let infos = []
      for (let i in this.dataPoints) {
        for (let ii in this.dataPoints[i]) {
          infos.push(this.dataPoints[i][ii])
        }
      }
      this.issuedShares = null
      let url = 'https://external.trading.10n.io/company/facts?symbol='
          + this.symbol
          + '&facts='
          + infos.join(',')
      + '&metric=' + this.metric
      const response = await fetch(url)
      this.actualData = await response.json()
      // console.log(this.actualData)

      this.outstandingShares = parseInt(this.filterSource('Outstanding Shares', true))
      // console.log(this.outstandingShares, this.filterSource('Outstanding Shares', true))
      if (!this.outstandingShares) {
        this.outstandingShares = null
        return
      }

      for (let i in this.dataRows) {
        let name = this.dataRows[i]
        let total = this.filterSource(name)
        // console.log(name, total)
        if (total !== null) {
          let perShare = ((total / this.outstandingShares) * 100).toFixed(0) / 100

          if (isNaN(perShare) || name === 'Outstanding Shares') {
            perShare = ''
          }
          total = this.$dividify(total, 2).join('')
          this.calculatedRows.push([
            name, total, perShare
          ])
        }
      }
    },
    async getQuote() {
      let url = 'https://external.trading.10n.io/company/quote?symbol=' + this.symbol
      const response = await fetch(url)
      this.quote = await response.json()
    }
  },
  created() {
    this.getQuote()
    this.loadFinancialInformation()
  },
  watch: {
    metric: function() {
      this.loadFinancialInformation()
    }
  }
}
</script>

<style scoped>
table {
  width: 100%;
}

tbody {
  font-size: 0.9em;
}

.nowrap {
  white-space: nowrap;
}

.additional-data {
  font-size: 0.8em;
}

.context td {
  background-color: #0f2a44;
  font-size: 0.9em;
  padding: 3px;
}
.debits-and-credits-selector {
  text-align: center;
}
button.selected {
  background-color: #B7BBC0;
  color: #0B2F51;
}
</style>
