<template>
  <table>
    <thead>
    <tr>
      <th>Name</th>
      <th>Trigger</th>
      <th>Date</th>
      <th>Data</th>
    </tr>
    </thead>
    <tbody>
      <tr v-for="(data, id) in flags" :key="id">
        <td>{{data.name}}</td>
        <td>{{data.trigger_mark.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          precision: 3
        })}}</td>
        <td>{{new Date(data.date).toLocaleDateString('en-us', { day:"numeric", month:"short"})}}</td>
        <td>
          <ul>
            <li v-for="(value, name) in filterData(data)" :key="name">
              <template v-if="name !== 'original_context'">
              <span>{{name}}</span>:
              <span>{{value}}</span>
              </template>
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "FlagList",
  props: ['symbol'],
  data() {
    return {
      flags: []
    }
  },
  methods: {
    filterData(data) {
      const ignore = ['name', '_id', 'symbol', 'date', 'trigger_mark']
      let ret = {}
      for (let i in data) {
        if (ignore.indexOf(i) >= 0) {
          continue
        }
        let value = data[i]
        console.log()
        if (typeof value === 'number') {
          value = parseFloat(value)
          value = Math.round(value * 10000) / 10000
        } else if (typeof value === 'string') {
          const date = new Date(value)
          if (typeof date.getTime() === 'number') {
            value = date.toLocaleDateString('en-us', { day:"numeric", month:"short"})
          }
        }
        ret[i] = value
      }
      return ret
    },
    async getFlags() {
      const request = await fetch('https://external.trading.10n.io/company/flags?symbol=' + this.symbol)
      this.flags = await request.json()
    }
  },
  created() {
    this.getFlags()
  }
}
</script>

<style scoped>
table {
  width: 100%;
}
td {
  vertical-align: top;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  margin: 0;
}
</style>
