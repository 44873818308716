<template>
  <div>
    <button @click="snapshot = true" :class="snapshot?'active':''">Current</button>
    <button @click="snapshot = false" :class="snapshot?'':'active'">Changes</button>
    <financial-information :symbol="$route.params.symbol" :key="$route.params.symbol" v-if="snapshot"/>
  </div>
</template>

<script>
import FinancialInformation from "@/components/FinancialInformation";
export default {
  name: "FinancialInformationView",
  components: {FinancialInformation},
  data() {
    return {
      snapshot: true
    }
  }
}
</script>

<style scoped>

</style>
