<template>
  <div class="highcharts-container">
    <button @click="timeframe='6m'" :class="timeframe === '6m' ? 'active' : ''">6 Months</button>
    <button @click="timeframe='3m'" :class="timeframe  === '3m'? 'active' : ''">3 Months</button>
    <button @click="timeframe='today'" :class="timeframe  === 'today'? 'active' : ''">Today</button>
    <span v-if="endDate" class="end-date">Ending: {{endDate}}</span>
    <highcharts
        :options="chartOptions"
        :updateArgs="updateArgs"
        :instance="instance"
        :constructor-type="'stockChart'"
    />

  </div>
</template>

<script>

import Highcharts from 'highcharts'
import {Chart} from 'highcharts-vue'
import Stock from 'highcharts/modules/stock'
import HHollowCandleStick from 'highcharts/modules/hollowcandlestick'

Stock(Highcharts)
HHollowCandleStick(Highcharts)

export default {
  name: "CandleChart",
  components: {highcharts: Chart},
  props: ['symbol'],
  data() {
    return {
      timeframe: '6m',
      updateArgs: [true, true, true],
      instance: null,
      endDate: '',
      chartOptions: {
        chart: {
          backgroundColor: '#0B2F51',
          animation: false,
        },
        rangeSelector: {
          enabled: false
        },
        navigator: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        scrollbar: {
          barBackgroundColor: 'none',
          barBorderRadius: 0,
          barBorderWidth: 0,
          buttonBackgroundColor: 'none',
          buttonBorderWidth: 0,
          buttonBorderRadius: 0,
          trackBackgroundColor: 'none',
          trackBorderWidth: 0,
          trackBorderRadius: 0,
          trackBorderColor: 'none'
        },
        yAxis: [{
          labels: {
            align: 'right',
            x: -3,
            style: {
              color: '#B7BBC0'
            }
          },
          animation: false,
          title: {
            enabled: false
          },
          height: '60%',
          lineWidth: 2,
          resize: {
            enabled: false
          },
          gridLineColor: '#305272'
        }, {
          labels: {
            align: 'right',
            x: -3,
            style: {
              color: '#B7BBC0'
            }
          },
          animation: false,
          title: {
            enabled: false
          },
          top: '65%',
          height: '35%',
          offset: 0,
          lineWidth: 0,
          gridLineColor: '#305272'
        }],
        series: [
          {
            type: 'candlestick',
            animation: false,
            name: this.symbol,
            data: [],
            upColor: 'greenyellow',
            color: 'orangered',
            lineWidth: 0,
            dataGrouping: {
              enabled: false
            }
          }, {
            type: 'column',
            name: 'Volume',
            animation: false,
            color: '#667D94',
            data: [],
            yAxis: 1,
            lineWidth: 0,
            dataGrouping: {
              enabled: false
            }
          }
        ]
      }
    }
  },
  methods: {
    async loadData() {
      const response = await fetch('https://external.trading.10n.io/quote/quote?symbol=' + this.symbol + '&tf=' + this.timeframe)
      let data
      try {
        data = await response.json()
      } catch (e) {
        this.endDate = 'No data'
        return
      }
      this.chartOptions.series[0].data = []
      this.chartOptions.series[1].data = []
      const dataDate = new Date(data.date)
      const today = new Date()
      if (today.getDay() !== dataDate.getDay()) {
        this.endDate = dataDate.toDateString()
      } else {
        this.endDate = ''
      }
      for (let d in data.data) {
        this.chartOptions.series[0].data.push([
            data.data[d].created_at,
            data.data[d].open,
            data.data[d].high,
            data.data[d].low,
            data.data[d].lastTrade,
        ])
        this.chartOptions.series[1].data.push([
            data.data[d].created_at,
            data.data[d].volume,
        ])
      }
    }
  },
  created() {
    this.loadData()
  },
  watch: {
    timeframe: function() {
      this.loadData()
    },
    symbol: function() {
      this.loadData()
    }
  }
}
</script>

<style scoped>
.highcharts-container {
  padding: 0;
}

.end-date {
  font-size: 0.8em;
  position: absolute;
  padding-left: 6px;
  padding-top: 14px;
}
</style>
