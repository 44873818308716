import { createRouter, createWebHistory } from 'vue-router'
import FinancialInformationView from "@/views/FinancialInformationView";
import HomeView from "@/views/HomeView";
import FlagView from "@/views/FlagView";
import ChartView from "@/views/ChartView";
import HeldByView from "@/views/HeldByView";
import IndustryComparisonView from "@/views/IndustryComparisonView";
import TechnicalView from "@/views/TechnicalView";
import SecFilingsView from "@/views/SecFilingsView";
import ConnectionsView from "@/views/ConnectionsView";
import NewsArticleView from "@/views/NewsArticleView";
import AboutView from "@/views/AboutView";
import StatsView from "@/views/StatsView";
import LinksView from "@/views/LinksView";
import RelationshipsView from "@/views/RelationshipsView";
import SearchView from "@/views/SearchView";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/search/:search',
    name: 'search',
    component: SearchView
  },
  {
    path: '/:symbol/financial',
    name: 'financial',
    component: FinancialInformationView,
    props: true
  },
  {
    path: '/:symbol/chart',
    name: 'chart',
    component: ChartView,
    props: true
  },
  {
    path: '/:symbol/news',
    name: 'news',
    component: NewsArticleView,
    props: true
  },
  {
    path: '/:symbol/flags',
    name: 'flags',
    component: FlagView,
    props: true
  },
  {
    path: '/:symbol/heldby',
    name: 'heldby',
    component: HeldByView,
    props: true
  },
  {
    path: '/:symbol/industry',
    name: 'industry',
    component: IndustryComparisonView,
    props: true
  },
  {
    path: '/:symbol/technical',
    name: 'technical',
    component: TechnicalView,
    props: true
  },
  {
    path: '/:symbol/sec',
    name: 'sec',
    component: SecFilingsView,
    props: true
  },
  {
    path: '/:symbol/connections',
    name: 'connections',
    component: ConnectionsView,
    props: true
  },
  {
    path: '/:symbol/about',
    name: 'about',
    component: AboutView,
    props: true
  },
  {
    path: '/:symbol/stats',
    name: 'stats',
    component: StatsView,
    props: true
  },
  {
    path: '/:symbol/links/:relationships?',
    name: 'links',
    component: LinksView,
    props: true
  },
  {
    path: '/:symbol/relationships/:relationships/:action?',
    name: 'relationships',
    component: RelationshipsView,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
