<template>
  <ul v-if="isRoot">
    <object-renderer v-for="(stat, name) in value" :key="name" :name="name" :value="stat"/>
  </ul>
  <li v-else-if="typeof value === 'object' || Array.isArray(value)">
    <span class="name" @click="showChildren = !showChildren">{{filterName(name)}}: </span>
    <span v-if="showChildren" class="expand">&#10134;</span>
    <ul v-if="showChildren">
      <object-renderer v-for="(stat, name) in value" :key="name" :name="name" :value="stat"/>
    </ul>
    <span class="value" v-else>{{filterValue(value)}}</span>

    <span v-if="!showChildren" class="expand">&#10133;</span>
  </li>
  <li v-else>

    <span class="name">{{filterName(name)}}: </span>
    <span class="value">{{filterValue(value)}}</span>
  </li>
</template>

<script>
export default {
  name: "ObjectRenderer",
  props: ['name', 'value', 'prefix', 'isRoot'],
  data() {
    return {
      showChildren: false
    }
  },
  methods: {
    filterName(name) {
      if (typeof name !== 'string') {
        return ''
      }

      if (name.length === 3 && ['was', 'low', 'dev'].indexOf(name) === -1) {
        return name.toUpperCase()
      }
      if (name.split) {
        let parts = name.split('_')
        if (parts.length > 1) {
          for (let i in parts) {
            parts[i] = this.filterName(parts[i])
          }
          name = parts.join(' ')
        }
      }
      return name[0].toUpperCase() + name.substring(1)
    },
    filterValue(value) {
      if (typeof value === 'object') {
        const keys = Object.keys(value)
        return this.filterValue(value[keys[0]])
      }
      const dateInstance = new Date(value)
      let dateIso = dateInstance.toISOString()
      let dateValue = value
      if (dateValue.indexOf && dateValue.indexOf('.') > 0 && dateIso && dateIso.indexOf('.') > 0) {
        dateValue = dateValue.substring(0, dateValue.indexOf('.'))
        dateIso = dateIso.substring(0, dateIso.indexOf('.'))
        if (dateIso === dateValue) {
          return dateInstance.toLocaleDateString()
        }
      }
      if (!isNaN(parseFloat(value))) {
        value = Math.round(value * 10000) / 10000
      }
      return value
    }
  }
}
</script>

<style scoped>

</style>
