<template>
  <div>
    <table>
      <tr>
      <td v-for="(quote, symbol) in quotes" :key="symbol">
          <span class="description">{{ filterName(quote.description) }}</span>
          <!--        <span class="symbol">{{quote.symbol}}</span>-->
        <span :class="quote.markChange >= 0 ? 'green' : 'red'">
          <span class="mark-change">{{ parseInt(quote.markChange * 100) / 100 }}</span>
          <span class="mark-change-percentage">{{ parseInt(quote.markPercChange * 100) / 100 }}%</span>
          <span class="mark">{{ parseInt(quote.mark * 10) / 10}}</span>
        </span>
      </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "IndexSummary",
  data() {
    return {
      timer: false,
      symbols: ['COMP.IDX','DJIND','SPX','RUT'],
      quotes: [],
      individualWidth: 0
    }
  },
  methods: {
    filterName(name) {
      if (!name) { return '(unknown)'}
      name = name.split(' ')
      return name[0]
    },
    async getIndexes() {
      const response = await fetch('https://external.trading.10n.io/powertrader/quote?attributes=mark,markChange,markPercChange,description&symbols=' + this.symbols.join(','))
      this.quotes = await this.getJson(response)
    },
    async getJson(response) {
      return await response.json()
    }
  },
  mounted() {
    this.getIndexes()
    this.timer = setInterval(this.getIndexes, 30000)
  },
  unmounted() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>

<style scoped>
table {
  background-color: #0a2036;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

li {
  display: inline-block;
}

li p {
  margin: 0;
  padding: 0;
  width: 40%;
}

span {
  font-size: 0.9em;
}

.description {
  display: inline;
  padding-right: 4px;
}

.mark, .mark-change {
  display: none;
}

.mark, .mark-change, .mark-change-percentage {
  padding-right: 4px;
  white-space: nowrap;
}

.red {
  color: orangered;
}

.green {
  color:greenyellow;
}

@media only screen and (min-width: 768px) {
  .mark, .mark-change {
    display: inline;
  }

  .description {
    display: block;
  }
}

</style>
