<template>
  <div>
    <table>
      <thead>
      <tr>
        <th>Top Debits</th>
        <th>Top Credits</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          <ul>
            <li v-for="(value, name) in debits" :key="'debits' + name">
              <span>{{name}}</span>
              <span>{{$dividify(value).join('')}}</span>
            </li>
          </ul>
        </td>
        <td>
          <ul>
            <li v-for="(value, name) in credits" :key="'credits' + name">
              <span>{{name}}</span>
              <span>{{$dividify(value).join('')}}</span>
            </li>
          </ul>
        </td>
      </tr>
      </tbody>
    </table>
    <spinner v-if="loading" />
  </div>
</template>

<script>
import Spinner from "@/components/Spinner";
export default {
  name: "CreditsDebits",
  components: {Spinner},
  props: ['symbol'],
  data() {
    return {
      loading: true,
      credits: {},
      debits: {},
      limit: 25
    }
  },
  methods: {
    async loadData() {
      this.loading = true
      const response = await fetch('https://external.trading.10n.io/company/report?symbol=' + this.symbol)
      const json = await response.json()
      this.credits = json.credits
      this.debits = json.debits
      this.loading = false
    }
  },
  created() {
    this.loadData()
  }
}
</script>

<style scoped>
table {
  width: 100%;
}
th {
  text-align: center;
}
td {
  vertical-align: top;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  border-bottom: 1px solid #2c3e50;
}
span:first-child {
  padding-right: 10px;
  font-size: 0.8em;
  font-weight: bold;
}
span:last-child {
  font-size: 0.75em;
  display: block;
  background-color: #0f2a44;
}
</style>
